import dateFormat from 'dateformat'

export function currency(number, currencyLocale) {
    if (number === null) {
        return ''
    }
    // default locale de_DE
    let locale = 'de-DE'
    let currency = 'EUR'

    if (currencyLocale === 'en_NZ') {
        locale = 'en-NZ'
        currency = 'NZD'
    }
    let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    })
    return formatter.format(number)
}

export function localize_float(number) {
    return parseFloat(number).toLocaleString('de-DE')
}

export function pluralize(number, singular, plural) {
    const pluralized = number == 1 ? singular : plural
    return number.toString() + ' ' + pluralized
}

export function localize_date(date, language_code) {
    return dateFormat(
        date,
        language_code.startsWith('de') ? 'dd.mm.yyyy' : 'dd/mm/yyyy'
    )
}
