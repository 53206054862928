<template>
    <div class="basket-line">
        <div class="row spaceless tr table-form">
            <div class="col-xs-12" v-if="show_errors">
                <div v-for="(errors, key) in line_errors" :key="key">
                    <div v-if="key !== 'quantity'">
                        <div
                            v-for="error in errors"
                            class="alert alert-danger alert-dismissible"
                            :key="key + error"
                            role="alert"
                        >
                            <a class="close" @click="clear_errors" href="#"
                                ><i class="fa fa-times"></i
                            ></a>
                            {{ error }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 hidden-xs">
                <div class="row spaceless">
                    <div class="col-sm-2 td overflow-hidden">
                        {{ line.stock_record.partner_sku }}
                    </div>
                    <div
                        v-bind:class="{
                            'overflow-hidden td col-sm-6': is_regular_line,
                            'overflow-hidden td col-sm-10': !is_regular_line,
                        }"
                    >
                        <a
                            class="text-bold no-decoration text-nowrap"
                            role="button"
                            data-toggle="collapse"
                            :href="'#product_details-' + line.product.id"
                            aria-expanded="false"
                            :aria-controls="
                                'product_details-' + line.product.id
                            "
                            :title="
                                gettext('Informationen zum Produkt anzeigen')
                            "
                        >
                            {{ line.product.title }}
                            <i class="fa fa-caret-down"></i>
                        </a>
                    </div>
                    <div class="col-sm-4" v-if="is_regular_line">
                        <div
                            class="table-form-element delivery_date"
                            v-if="can_change_delivery_date"
                        >
                            <date-picker
                                :min_date="
                                    parse_date(line.earliest_delivery_date)
                                "
                                :set_date="(date) => set_line_date(line, date)"
                                :date="parse_date(line.requested_delivery_date)"
                            ></date-picker>
                            <!--props: ['errors', 'min_date', 'set_date', 'date'],-->
                        </div>
                        <div v-else class="delivery_date">
                            <span class="td">
                                {{ formatted_delivery_date }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 visible-xs">
                <div class="row spaceless">
                    <div class="td col-xs-12 overflow-hidden">
                        <h4>
                            <a
                                class="text-nowrap no-decoration"
                                role="button"
                                data-toggle="collapse"
                                :href="'#product_details_xs-' + line.product.id"
                                aria-expanded="false"
                                :aria-controls="
                                    'product_details_xs-' + line.product.id
                                "
                                :title="
                                    gettext(
                                        'Informationen zum Produkt anzeigen'
                                    )
                                "
                            >
                                {{ line.product.title }}
                                <i class="fa fa-caret-down"></i>
                            </a>

                            <small>{{ line.stock_record.partner_sku }}</small>
                        </h4>
                        <div
                            class="collapse"
                            :id="'product_details_xs-' + line.product.id"
                        >
                            <product_details :line="line"></product_details>
                        </div>
                    </div>
                </div>
                <div class="row spaceless">
                    <div class="col-xs-12">
                        <div class="th" v-if="is_regular_line">
                            {{ gettext('Lieferdatum') }}
                        </div>
                        <div class="delivery_date td" v-if="is_regular_line">
                            <date-picker
                                v-if="can_change_delivery_date"
                                :min_date="
                                    parse_date(line.earliest_delivery_date)
                                "
                                :set_date="(date) => set_line_date(line, date)"
                                :date="parse_date(line.requested_delivery_date)"
                            ></date-picker>
                            <div v-else class="delivery-date pt-2">
                                {{ formatted_delivery_date }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-6">
                <div class="row spaceless space-left-sm tr visible-xs">
                    <div class="th col-xs-6">
                        {{ gettext('Menge') }}
                        <quantities :line="line"></quantities>
                    </div>
                    <div
                        class="th col-xs-2 align-right text-nowrap"
                        v-if="is_regular_line"
                    >
                        {{ gettext('Rabatt') }}
                    </div>
                    <div class="th col-xs-4 align-right" v-if="is_regular_line">
                        {{ gettext('Preis') }}
                    </div>
                    <div
                        class="th col-xs-6 align-right"
                        v-if="!is_regular_line"
                    >
                        {{ gettext('Preis') }}
                    </div>
                </div>
                <div class="row spaceless space-left-sm tr">
                    <div
                        v-bind:class="{
                            'col-xs-6 col-md-6': is_regular_line,
                            'col-xs-6 col-sm-8': !is_regular_line,
                        }"
                    >
                        <span class="td">
                            <span class="text-nowrap">
                                {{
                                    pluralize(
                                        line.quantity,
                                        line.product.trade_unit.name,
                                        line.product.trade_unit.name_plural
                                    )
                                }}
                            </span>
                            <span class="hidden-xs">
                                <quantities :line="line"></quantities>
                            </span>
                        </span>
                    </div>
                    <div
                        class="col-md-2 hidden-xs hidden-sm td align-right"
                        v-if="is_regular_line"
                    >
                        {{
                            currency(line.unit_price_excl_tax, currency_locale)
                        }}
                    </div>
                    <div
                        class="col-xs-2 col-sm-3 col-md-2 td align-right discount-percentage"
                        v-if="is_regular_line"
                    >
                        <discount_breakdown
                            :breakdown="line.discount_breakdown"
                        ></discount_breakdown>
                    </div>
                    <div
                        class="col-xs-4 col-sm-3 col-md-2 td price_color align-right"
                        v-if="is_regular_line"
                    >
                        {{
                            currency(
                                line.line_price_excl_tax_incl_discounts,
                                currency_locale
                            )
                        }}
                    </div>
                    <div
                        class="col-xs-6 col-sm-4 td price_color align-right"
                        v-if="!is_regular_line"
                    >
                        {{ gettext('gratis') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="collapse" :id="'product_details-' + line.product.id">
            <product_details :line="line"></product_details>
        </div>
    </div>
    <!-- END .basket-line -->
    <!--{{ line.product.title }}-->
    <!--{{ line.quantity }}-->

    <!--<div v-for="field_errors in line.errors">-->
    <!--<div v-for="error in field_errors" class="alert alert-danger" role="alert">-->
    <!--{{ error }}-->
    <!--</div>-->
    <!--</div>-->
</template>

<script>
import Quantities from './quantities_edit.vue'
import DiscountBreakdown from './discount_breakdown.vue'
import ProductDetails from './product_details.vue'
import { currency, localize_float, pluralize } from '../format'
import DatePicker from './delivery_date.vue'
import dateFormat from 'dateformat'
import { parse_date } from '../utils'
import { localize_date } from '../format'
import { WDM_PRODUCT_CLASS } from '../store/modules/basket'
import 'bootstrap/js/collapse'

import { django_i18n } from '../django_i18n'
const _ = django_i18n.gettext

export default {
    props: ['line', 'currency_locale', 'can_change_delivery_date'],
    data() {
        return {}
    },
    methods: {
        currency,
        localize_float,
        pluralize,
        parse_date,
        clear_errors() {
            this.$store.dispatch('get_basket')
        },

        set_line_date(line, date) {
            const requested_delivery_date = dateFormat(date, 'yyyy-mm-dd')
            if (line.requested_delivery_date === requested_delivery_date) {
                return
            }
            this.$store.dispatch('update_line', {
                line: line,
                data: { requested_delivery_date },
                skipBasketUpdate: true,
            })
        },
    },
    components: {
        quantities: Quantities,
        discount_breakdown: DiscountBreakdown,
        'date-picker': DatePicker,
        product_details: ProductDetails,
    },
    computed: {
        formatted_delivery_date: function () {
            return localize_date(
                this.line.requested_delivery_date,
                this.language_code
            )
        },
        language_code() {
            return this.$store.state.basket.object.language_code
        },
        show_errors: function () {
            // returns true if there are any non-quantity-errors
            const line_error_keys = Object.keys(this.line_errors)
            if (
                line_error_keys.length === 1 &&
                line_error_keys[0] === 'quantity'
            ) {
                return false
            } else {
                return line_error_keys.length > 0
            }
        },

        line_errors: function () {
            let errors = {}
            Object.assign(errors, this.line.errors)
            if (this.has_missing_earliest_delivery_date) {
                errors['__all__'] = errors['__all__'] || []
                errors['__all__'].push(
                    _(
                        'Dieses Produkt ist momentan leider nicht verfügbar. Bitte klicken Sie auf "ändern" und entfernen Sie es aus ihrem Warenkorb um mit dem Einkauf fortzufahren.'
                    )
                )
            }
            if (this.has_missing_requested_delivery_date) {
                // this state has a really low probability of happening, but it is possible
                errors['__all__'] = errors['__all__'] || []
                errors['__all__'].push(
                    _(
                        'Bitte wählen Sie ein Lieferdatum indem sie auf das Kalendersymbol unterhalb von Lieferung klicken.'
                    )
                )
            }
            return errors
        },
        has_missing_earliest_delivery_date() {
            return (
                this.can_change_delivery_date &&
                this.is_regular_line &&
                this.line.earliest_delivery_date === null
            )
        },
        has_missing_requested_delivery_date() {
            return (
                this.can_change_delivery_date &&
                this.is_regular_line &&
                this.line.requested_delivery_date === null
            )
        },
        is_wdm_line() {
            return this.line.product.product_class === WDM_PRODUCT_CLASS
        },
        is_regular_line() {
            return this.line.product.product_class !== WDM_PRODUCT_CLASS
        },
    },
}
</script>

<style scoped></style>
