

'use strict';
{
  const globals = this;
  const django = globals.django || (globals.django = {});

  
  django.pluralidx = function(count) { return (count == 1) ? 0 : 1; };
  

  /* gettext library */

  django.catalog = django.catalog || {};
  
  const newcatalog = {
    "Abbrechen": "Cancel",
    "Aktualisieren": "Update",
    "Anzahl": "Quantity",
    "Art.-Nr.": "Art.-no.",
    "Artikel": "article",
    "Artikelnummer, Name oder GTIN eingeben": "Enter item number, name or GTIN",
    "Bestehende Adresse w\u00e4hlen": "Choose existing address",
    "Bestelllisten": "Wishlists",
    "Datum w\u00e4hlen": "Choose date",
    "Der Warenkorb konnte nicht geladen werden.": "Basket could not be loaded.",
    "Ein Fehler ist aufgetreten. Bitte laden Sie die Seite erneut und versuchen Sie es noch einmal.": "An error occurred. Please reload the page and try again.",
    "Ein Produkt in ihrem Warenkorb hat ein zu fr\u00fches oder nicht gesetztes Lieferdatum.": "A product in your basket has a delivery date, which is too early or not set.",
    "Entfernen": "Remove",
    "Fr\u00fchester Liefertermin": "Earliest delivery date",
    "GTIN": "GTIN",
    "Gesamt": "Total",
    "Gesamtbetr\u00e4ge": "Totals",
    "In den Warenkorb": "Add to basket",
    "Informationen zum Produkt anzeigen": "Show product information",
    "Ja": "Yes",
    "Jetzt kostenlos bestellen": "Order now free of charge",
    "Lieferdatum": "Delivery date",
    "Liefertermin w\u00e4hlen": "Choose delivery date",
    "Lieferung": "Delivery",
    "Menge": "Quantity",
    "Menge \u00e4ndern": "Change quantity",
    "Nein": "No",
    "Nettosumme": "Net total",
    "Neue Adresse anlegen": "Add new address",
    "Preis": "Price",
    "Produkt nicht gefunden": "Product not found",
    "Produkte": "Products",
    "Produkte in ihrem Warenkorb haben ein zu fr\u00fches oder nicht gesetztes Lieferdatum.": "Products in your basket have a delivery date, which is too early or not set.",
    "Produktmuster und Infomaterial": "Product sample and promotional material",
    "Produktseite von": "Product page of",
    "Rabatt": "Discount",
    "Speichern": "Save",
    "Summe": "Total",
    "Umsatzsteuer": "GST",
    "Versand": "Shipping",
    "Weiter zur Kasse": "Proceed to checkout",
    "gratis": "free",
    "\u00e4ndern": "change"
  };
  for (const key in newcatalog) {
    django.catalog[key] = newcatalog[key];
  }
  

  if (!django.jsi18n_initialized) {
    django.gettext = function(msgid) {
      const value = django.catalog[msgid];
      if (typeof value === 'undefined') {
        return msgid;
      } else {
        return (typeof value === 'string') ? value : value[0];
      }
    };

    django.ngettext = function(singular, plural, count) {
      const value = django.catalog[singular];
      if (typeof value === 'undefined') {
        return (count == 1) ? singular : plural;
      } else {
        return value.constructor === Array ? value[django.pluralidx(count)] : value;
      }
    };

    django.gettext_noop = function(msgid) { return msgid; };

    django.pgettext = function(context, msgid) {
      let value = django.gettext(context + '\x04' + msgid);
      if (value.includes('\x04')) {
        value = msgid;
      }
      return value;
    };

    django.npgettext = function(context, singular, plural, count) {
      let value = django.ngettext(context + '\x04' + singular, context + '\x04' + plural, count);
      if (value.includes('\x04')) {
        value = django.ngettext(singular, plural, count);
      }
      return value;
    };

    django.interpolate = function(fmt, obj, named) {
      if (named) {
        return fmt.replace(/%\(\w+\)s/g, function(match){return String(obj[match.slice(2,-2)])});
      } else {
        return fmt.replace(/%s/g, function(match){return String(obj.shift())});
      }
    };


    /* formatting library */

    django.formats = {
    "DATETIME_FORMAT": "N j, Y, P",
    "DATETIME_INPUT_FORMATS": [
      "%Y-%m-%d %H:%M:%S",
      "%Y-%m-%d %H:%M:%S.%f",
      "%Y-%m-%d %H:%M",
      "%m/%d/%Y %H:%M:%S",
      "%m/%d/%Y %H:%M:%S.%f",
      "%m/%d/%Y %H:%M",
      "%m/%d/%y %H:%M:%S",
      "%m/%d/%y %H:%M:%S.%f",
      "%m/%d/%y %H:%M",
      "%Y-%m-%d"
    ],
    "DATE_FORMAT": "N j, Y",
    "DATE_INPUT_FORMATS": [
      "%Y-%m-%d",
      "%m/%d/%Y",
      "%m/%d/%y",
      "%b %d %Y",
      "%b %d, %Y",
      "%d %b %Y",
      "%d %b, %Y",
      "%B %d %Y",
      "%B %d, %Y",
      "%d %B %Y",
      "%d %B, %Y"
    ],
    "DECIMAL_SEPARATOR": ".",
    "FIRST_DAY_OF_WEEK": 0,
    "MONTH_DAY_FORMAT": "F j",
    "NUMBER_GROUPING": 3,
    "SHORT_DATETIME_FORMAT": "m/d/Y P",
    "SHORT_DATE_FORMAT": "m/d/Y",
    "THOUSAND_SEPARATOR": ",",
    "TIME_FORMAT": "P",
    "TIME_INPUT_FORMATS": [
      "%H:%M:%S",
      "%H:%M:%S.%f",
      "%H:%M"
    ],
    "YEAR_MONTH_FORMAT": "F Y"
  };

    django.get_format = function(format_type) {
      const value = django.formats[format_type];
      if (typeof value === 'undefined') {
        return format_type;
      } else {
        return value;
      }
    };

    /* add to global namespace */
    globals.pluralidx = django.pluralidx;
    globals.gettext = django.gettext;
    globals.ngettext = django.ngettext;
    globals.gettext_noop = django.gettext_noop;
    globals.pgettext = django.pgettext;
    globals.npgettext = django.npgettext;
    globals.interpolate = django.interpolate;
    globals.get_format = django.get_format;

    django.jsi18n_initialized = true;
  }
};

