import * as api from '../../api'
import * as types from '../mutation-types'
import { sendBasketToPiwik } from '../../piwik'

// product classes
export const WDM_PRODUCT_CLASS = 'wdm'
export const PDM_PRODUCT_CLASS = 'pdm'

// initial state
const state = {
    object: { lines: [] }, // the basket itself
    options: {}, // basket options like shipping method choices
    calendar: {
        business_weekdays: {},
        non_business_days: [],
    },
    errors: {},
    requests_in_progress: 0,
}

const getters = {
    basket_request_in_progress(state) {
        return state.requests_in_progress > 0
    },
    basket_wdm_lines(state) {
        return state.object.lines.filter(
            (line) => line.product.product_class === WDM_PRODUCT_CLASS
        )
    },
    basket_regular_lines(state) {
        return state.object.lines.filter(
            (line) => line.product.product_class !== WDM_PRODUCT_CLASS
        )
    },
}

const actions = {
    get_basket({ commit }) {
        return new Promise((success, error) => {
            commit(types.BASKET_REQUEST_START)
            api.get_basket(
                (basket) => {
                    commit(types.BASKET_REQUEST_END)
                    commit(types.BASKET_CHANGE, { basket })
                    success(basket)
                },
                (errors) => {
                    commit(types.BASKET_REQUEST_END)
                    commit(types.BASKET_ERRORS, { errors })
                    error(errors)
                }
            )
        })
    },
    update_basket({ commit, state }, { data }) {
        let projected = {}
        let basket = {}
        Object.assign(basket, state.object)
        Object.assign(projected, basket, data)
        commit(types.BASKET_REQUEST_START)
        commit(types.BASKET_CHANGE, { basket: projected })
        api.update_basket(
            data,
            (basket) => {
                commit(types.BASKET_REQUEST_END)
                commit(types.BASKET_CHANGE, { basket })
            },
            (errors) => {
                // revert and show errors
                commit(types.BASKET_CHANGE, { basket })
                commit(types.BASKET_REQUEST_END)
                commit(types.BASKET_ERRORS, { errors })
            }
        )
    },
    get_calendar({ commit }) {
        commit(types.BASKET_REQUEST_START)
        api.get_calendar(
            (calendar) => {
                commit(types.BASKET_REQUEST_END)
                commit(types.CALENDAR_CHANGE, { calendar })
            },
            (errors) => {
                commit(types.BASKET_REQUEST_END)
                commit(types.CALENDAR_ERRORS, { errors })
            }
        )
    },
    add_product({ dispatch, commit }, { product, quantity }) {
        commit(types.BASKET_REQUEST_START)
        api.add_product(
            product,
            quantity,
            (line) => {
                commit(types.BASKET_REQUEST_END)
                commit(types.LINE_CHANGE, { line })
                dispatch('get_basket').then((basket) => {
                    sendBasketToPiwik(basket)
                })
            },
            (errors) => {
                commit(types.BASKET_REQUEST_END)
                commit(types.BASKET_ERRORS, { errors })
            }
        )
    },
    update_line({ dispatch, commit }, { line, data, skipBasketUpdate }) {
        let projected = {}
        Object.assign(projected, line, data)
        commit(types.BASKET_REQUEST_START)
        commit(types.LINE_CHANGE, { line: projected })
        api.update_line(
            line,
            data,
            (line) => {
                commit(types.BASKET_REQUEST_END)
                commit(types.LINE_CHANGE, { line })
                if (!skipBasketUpdate) {
                    dispatch('get_basket').then((basket) => {
                        sendBasketToPiwik(basket)
                    })
                }
            },
            (errors) => {
                // reset line and show errors
                commit(types.BASKET_REQUEST_END)
                commit(types.LINE_CHANGE, { line })
                commit(types.LINE_ERRORS, { line, errors })
            }
        )
    },
    delete_line({ dispatch, commit }, { line }) {
        commit(types.BASKET_REQUEST_START)
        commit(types.LINE_DELETE, { line })
        api.delete_line(
            line,
            () => {
                commit(types.BASKET_REQUEST_END)
                dispatch('get_basket').then((basket) => {
                    sendBasketToPiwik(basket)
                })
            },
            ({ errors }) => {
                commit(types.BASKET_REQUEST_END)
                // show error and refresh basket
                commit(types.BASKET_ERRORS, { errors })
                dispatch('get_basket')
            }
        )
    },
}

// mutations
const mutations = {
    [types.BASKET_REQUEST_START](state) {
        state.requests_in_progress += 1
    },
    [types.BASKET_REQUEST_END](state) {
        state.requests_in_progress -= 1
    },
    [types.BASKET_CHANGE](state, { basket }) {
        state.object = basket
    },
    [types.BASKET_ERRORS](state, { errors }) {
        state.errors = errors
    },
    [types.CALENDAR_CHANGE](state, { calendar }) {
        state.calendar = calendar
    },
    [types.CALENDAR_ERRORS](state, { errors }) {
        state.calendar = { errors }
    },
    [types.LINE_CHANGE](state, { line }) {
        state.object.lines = state.object.lines.map(function (l) {
            return l.url === line.url ? line : l
        })
    },
    [types.LINE_DELETE](state, { line }) {
        state.object.lines = state.object.lines.filter(function (l) {
            return l.url !== line.url
        })
    },
    [types.LINE_ERRORS](state, { line, errors }) {
        state.object.lines = state.object.lines.map(function (l) {
            if (l.url === line.url) {
                line.errors = errors
            }
            return l
        })
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
