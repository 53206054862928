<template>
    <div ref="root" v-bind:class="{ 'inline-block': button_text.length > 0 }">
        <div v-if="button_text.length > 0">
            <div class="hidden-fixed" style="visibility: hidden">
                <!-- style for ie9 compat, don't move to css -->
                <input
                    class="form-control fp-input"
                    type="text"
                    :value="date_string"
                />
            </div>
            <a href="#" v-on:click.prevent="toggle">{{ button_text }}</a>
        </div>
        <div
            v-if="button_text.length === 0"
            :class="is_date_wrong ? 'has-error' : ''"
        >
            <span class="selected-date" style="display: none">{{
                date_string
            }}</span
            ><!-- used for tests -->
            <input
                class="form-control fp-input flatpickr-input"
                type="text"
                :value="date_string"
                v-bind:class="{ is_set: show_set_to_earliest_button() }"
                :title="gettext('Liefertermin wählen')"
            />
            <button
                class="btn btn-additional-info divider-left condensed"
                type="submit"
                :title="gettext('Frühester Liefertermin')"
                v-on:click.prevent="set_to_earliest()"
                v-if="show_set_to_earliest_button()"
            >
                <i class="fa fa-fw fa-rotate-left"></i>
            </button>
            <button
                class="btn btn-additional-info divider-left condensed"
                type="button"
                :title="gettext('Liefertermin wählen')"
                v-on:click.prevent="toggle"
                v-else
            >
                <i class="fa fa-fw fa-calendar"></i>
            </button>
        </div>
    </div>
</template>

<script>
import Flatpickr from 'flatpickr'
import { German as flatpickr_de } from 'flatpickr/dist/l10n/de.js'
import { French as flatpickr_fr } from 'flatpickr/dist/l10n/fr.js'
import dateFormat from 'dateformat'
import jQuery from 'jquery'
import { localize_date } from '@/format'

let $ = jQuery
export default {
    props: {
        min_date: Date,
        set_date: {},
        date: Date,
        button_text: { default: '' },
    },
    watch: {
        flatpickr_options: {
            // update all flatpickr options but the callback once our state changes
            handler: function (newOpt) {
                Object.keys(newOpt).forEach((key) => {
                    if (key !== 'onChange') {
                        this.fp.set(key, newOpt[key])
                    }
                })
            },
            deep: true,
        },
        date: function () {
            this.fp.setDate(this.date)
        },
    },
    mounted() {
        let elem = $('input.fp-input', this.$refs.root)
        this.fp = new Flatpickr(elem, this.flatpickr_options)
        if (this.date === null) {
            elem.value = ''
        }
    },
    methods: {
        on_change(dates) {
            const date = dates[0]
            if (date === undefined) {
                return
            }
            this.set_date(date)
        },
        set_to_earliest: function () {
            this.set_date(this.min_date)
        },
        show_set_to_earliest_button: function () {
            if (!this.date) {
                return false
            }
            const delivery = dateFormat(this.date, 'yyyymmdd'),
                min_date = dateFormat(this.min_date, 'yyyymmdd')
            return delivery !== min_date
        },
        is_date_enabled: function (date) {
            const weekday_name = [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ][dateFormat(date, 'N') - 1]
            if (this.business_weekdays[weekday_name] === false) {
                return false
            }
            const dateStr = dateFormat(date, 'yyyy-mm-dd')
            return this.non_business_days.indexOf(dateStr) === -1
        },
        toggle: function () {
            if (!this.fp.isOpen) {
                setTimeout(() => this.fp.open(), 0)
            }
        },
    },
    computed: {
        is_date_wrong: function () {
            return this.date === null || this.date < this.min_date
        },
        business_weekdays: function () {
            return this.$store.state.basket.calendar.business_weekdays
        },
        non_business_days: function () {
            return this.$store.state.basket.calendar.non_business_days
        },
        date_string: function () {
            if (this.date === null) {
                return ''
            }
            return localize_date(this.date, this.language_code)
        },
        language_code() {
            return this.$store.state.basket.object.language_code
        },
        flatpickr_options: function () {
            let options = {
                onChange: this.on_change,
                defaultDate: this.date,
                minDate: this.min_date,
                enable: [this.is_date_enabled],
            }
            if (this.language_code.startsWith('en')) {
                options.dateFormat = 'd/m/Y'
            }
            if (this.language_code.startsWith('fr')) {
                options.dateFormat = 'd/m/Y'
                options.locale = flatpickr_fr
            }
            if (this.language_code.startsWith('de')) {
                options.locale = flatpickr_de
                options.dateFormat = 'd.m.Y'
            }
            // FIXME watching date objects may result in changes that aren't real
            // because objects may not be identical even though they haven't changed
            return options
        },
    },
}
</script>

<style scoped></style>
