export const BASKET_REQUEST_START = 'BASKET_REQUEST_START'
export const BASKET_REQUEST_END = 'BASKET_REQUEST_END'
export const BASKET_CHANGE = 'BASKET_CHANGE'
export const BASKET_ERRORS = 'BASKET_ERRORS'

export const CALENDAR_CHANGE = 'CALENDAR_CHANGE'
export const CALENDAR_ERRORS = 'CALENDAR_ERRORS'

export const LINE_DELETE = 'LINE_DELETE'
export const LINE_CHANGE = 'LINE_CHANGE'
export const LINE_ERRORS = 'LINE_ERRORS'

export const STOCKRECORDS_QUERY_START = 'STOCKRECORDS_QUERY_START'
export const STOCKRECORDS_QUERY_END = 'STOCKRECORDS_QUERY_END'
export const STOCKRECORDS_CHANGE = 'STOCKRECORDS_CHANGE'
export const STOCKRECORDS_DELETE = 'STOCKRECORDS_DELETE'
export const STOCKRECORDS_ERRORS = 'STOCKRECORDS_ERRORS'
