<template>
    <div class="basket-component">
        <div
            class="spinner basket-spinner basket-busy"
            v-if="request_in_progress"
        >
            <i class="fa fa-spinner fa-spin fa-4x"></i>
        </div>

        <div
            v-for="error in errors"
            @click="clear_errors"
            class="alert alert-danger"
            :key="error"
            role="alert"
        >
            <a class="close" @click="clear_errors" href="#">&times;</a>
            {{ error }}
        </div>

        <div
            v-if="lines_with_wrong_delivery_dates.length > 0"
            class="alert alert-warning"
            role="alert"
        >
            <span v-if="lines_with_wrong_delivery_dates.length === 1">
                {{
                    gettext(
                        'Ein Produkt in ihrem Warenkorb hat ein zu frühes oder nicht gesetztes Lieferdatum.'
                    )
                }}
            </span>
            <span v-else>
                {{ lines_with_wrong_delivery_dates.length }}
                {{
                    gettext(
                        'Produkte in ihrem Warenkorb haben ein zu frühes oder nicht gesetztes Lieferdatum.'
                    )
                }}
            </span>
            <button
                class="btn btn-success align-right"
                @click="fix_wrong_delivery_dates"
            >
                {{ gettext('Aktualisieren') }}
            </button>
        </div>

        <div v-if="regular_lines.length > 0">
            <div class="basket-items grid-item">
                <div class="basket-title hidden-xs">
                    <div class="row spaceless tr">
                        <div class="col-sm-6">
                            <div class="row spaceless">
                                <div class="col-sm-2 th h4">
                                    {{ gettext('Art.-Nr.') }}
                                </div>
                                <div class="col-sm-6 th h3">
                                    {{ gettext('Produkte') }}
                                </div>
                                <div class="col-sm-4 th h4">
                                    {{ gettext('Lieferung') }}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row spaceless space-left">
                                <div class="col-sm-6 th h4">
                                    {{ gettext('Menge') }}
                                </div>
                                <div class="hidden-sm col-md-2 th h4">
                                    {{ gettext('Preis') }}
                                </div>
                                <div class="col-sm-3 col-md-2 th h4">
                                    {{ gettext('Rabatt') }}
                                </div>
                                <div class="col-sm-3 col-md-2 th h4">
                                    {{ gettext('Gesamt') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <basket-line
                    v-for="line in regular_lines"
                    v-bind:line="line"
                    :currency_locale="basket.currency_locale"
                    :key="line.id"
                    :can_change_delivery_date="basket.can_change_delivery_date"
                ></basket-line>
            </div>
        </div>

        <div v-if="wdm_lines.length > 0">
            <div class="basket-items grid-item">
                <div class="basket-title hidden-xs">
                    <div class="row spaceless tr">
                        <div class="col-sm-6">
                            <div class="row spaceless">
                                <div class="col-sm-2 th h4">
                                    {{ gettext('Art.-Nr.') }}
                                </div>
                                <div class="col-sm-10 th h3">
                                    {{
                                        gettext(
                                            'Produktmuster und Infomaterial'
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 space-left">
                            <div class="row spaceless space-left">
                                <div class="col-sm-8 th h4">
                                    {{ gettext('Menge') }}
                                </div>
                                <div class="col-sm-4 th h4 align-right">
                                    {{ gettext('Gesamt') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <basket-line
                    v-for="line in wdm_lines"
                    v-bind:line="line"
                    :key="line.id"
                ></basket-line>
            </div>
        </div>

        <div v-if="basket.lines.length > 0">
            <div class="row">
                <div class="col-sm-6">
                    <slot name="add-wishlist-to-basket"></slot>
                    <div class="visible-xs">
                        <slot name="save-basket-as-wishlist-xs"></slot>
                    </div>
                    <div v-if="regular_lines.length > 0">
                        <shipping-method :basket="basket"></shipping-method>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="hidden-xs pull-right">
                        <slot name="save-basket-as-wishlist"></slot>
                    </div>
                    <div class="clearfix"></div>
                    <div v-if="regular_lines.length > 0">
                        <basket-totals :basket="basket"></basket-totals>
                    </div>
                </div>
            </div>

            <div class="clearfix grid-item">
                <div class="pull-right">
                    <a
                        role="link"
                        :aria-disabled="request_in_progress"
                        :href="request_in_progress ? null : '/checkout/'"
                        :class="
                            'btn btn-lg btn-primary submit ' +
                            (request_in_progress ? 'disabled' : '')
                        "
                    >
                        <span
                            v-if="
                                wdm_lines.length > 0 &&
                                regular_lines.length === 0
                            "
                            >{{ gettext('Jetzt kostenlos bestellen') }}</span
                        >
                        <span v-else>{{ gettext('Weiter zur Kasse') }}</span>
                    </a>
                </div>
            </div>
        </div>

        <div v-show="basket.lines.length === 0">
            <slot name="empty-content"></slot>
            <h3>{{ gettext('Bestelllisten') }}</h3>
            <slot name="add-wishlist-to-basket-empty"></slot>
        </div>
    </div>
</template>

<script>
import BasketLine from './basket_line.vue'
import BasketTotals from './basket_totals.vue'
import ShippingMethod from './shipping_method.vue'
import * as types from '../store/mutation-types'

export default {
    props: {},
    data() {
        return {}
    },
    created() {
        this.$store.dispatch('get_basket')
        this.$store.dispatch('get_calendar')
    },
    methods: {
        clear_errors() {
            this.$store.commit(types.BASKET_ERRORS, { errors: [] })
            this.$store.dispatch('get_basket')
        },
        fix_wrong_delivery_dates() {
            this.lines_with_wrong_delivery_dates.map((line) => {
                this.$store.dispatch('update_line', {
                    line,
                    data: {
                        requested_delivery_date: line.earliest_delivery_date,
                    },
                    skipBasketUpdate: true,
                })
            })
        },
    },
    components: {
        'basket-line': BasketLine,
        'basket-totals': BasketTotals,
        'shipping-method': ShippingMethod,
    },
    computed: {
        request_in_progress() {
            return this.$store.getters.basket_request_in_progress
        },
        basket() {
            return this.$store.state.basket.object
        },
        lines_with_wrong_delivery_dates() {
            if (!this.basket.can_change_delivery_date) {
                return []
            }
            return this.regular_lines.filter(
                (line) =>
                    line.requested_delivery_date === null ||
                    line.requested_delivery_date < line.earliest_delivery_date
            )
        },
        wdm_lines() {
            return this.$store.getters.basket_wdm_lines
        },
        regular_lines() {
            return this.$store.getters.basket_regular_lines
        },
        errors() {
            // django rest framework returns either lists of strings or dicts with lists
            // of strings in them ...
            const basket_errors = this.$store.state.basket.errors
            if (basket_errors === undefined) {
                return []
            }
            let flat_errors = []
            let errors_array
            if (Array.isArray(basket_errors)) {
                errors_array = basket_errors
            } else {
                errors_array = Object.keys(basket_errors).map(
                    (k) => basket_errors[k]
                )
            }
            errors_array.map((error) => {
                if (!Array.isArray(error)) {
                    flat_errors.push(error)
                } else {
                    error.map((e) => flat_errors.push(e))
                }
            })
            return flat_errors
        },
    },
}
</script>

<style scoped>
.basket-component {
    position: relative;
}
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -70px;
    margin-left: -70px;
    font-size: 50px;
    color: rgba(51, 51, 51, 0.4);
    z-index: 2000;
}
</style>
